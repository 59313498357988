import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { H2, H3, Div, Button, P } from '../Elements';
import { ButtonCTA } from '../Button';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeEnablement } from '../../../contentful/content-types/blades/blade-enablement';
import sectionPadding from '../BladePadding';
import { ContentfulPage } from '../../../contentful/content-types/page';
import { MarketoFormModal } from '../Marketo/marketo-form-modal';

export function isContentfulBladeEnablement(
  blade: RelatedBlade
): blade is ContentfulBladeEnablement {
  return blade.contentTypeId === 'bladeEnablement';
}

interface EnablementBladeProps {
  blade: ContentfulBladeEnablement;
  page?: ContentfulPage;
}

const getClassnameFromBackgroundColor = new Map([
  ['blue', 'bg-blue0'],
  ['white', 'bg-white'],
]);

export const EnablementBlade: React.FC<EnablementBladeProps> = props => {
  const { blade, page } = props;
  const formModals = page?.formModal || [];
  const formModal1 = formModals.find(modal => modal.modalId === blade.buttonExtraClass);

  const formModal2 = formModals.find(modal => modal.modalId === blade.buttonModalId2);
  const [modal1IsOpen, setModal1IsOpen] = React.useState(false);

  const [modal2IsOpen, setModal2IsOpen] = React.useState(false);
  const containerClassnames = 'pt9-ns pb10-ns pt4 pb7';

  return (
    <Div className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor) || 'bg-white'}`}>
      {(blade.bladeSizeType === 'large' || !blade.bladeSizeType) && (
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Container>
            <Row className={`items-center`}>
              <Col xs={12} lg={7}>
                <H2 marginBottom={[6, 7]} color={blade.textColor || 'black'}>
                  {blade.title}
                </H2>
                <Div display="flex" flexWrap="wrap">
                  {blade.buttonText && (
                    <ButtonCTA
                      buttonStyle="auto"
                      buttonColor={blade.buttonColor}
                      href={blade.buttonUrl}
                      className={`mr4-l mb2 mb1-l`}
                      trackingCategory="newhome-acquisition-blade"
                      trackingAction="click"
                      trackingLabel={`sign_up-[${blade.buttonUrl}]`}
                      onClick={e => {
                        if (blade.buttonUrl === '#') {
                          e.preventDefault();
                          setModal1IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText}
                    </ButtonCTA>
                  )}
                  {blade.buttonText2 && (
                    <ButtonCTA
                      buttonColor={blade.buttonColor2}
                      className={`mr4-l mb0-l`}
                      href={blade.buttonUrl2}
                      buttonStyle="auto"
                      trackingCategory="newhome-acquisition-blade"
                      trackingAction="click"
                      trackingLabel={`sign_up-[${blade.buttonUrl2}]`}
                      onClick={e => {
                        if (blade.buttonUrl2 === '#') {
                          e.preventDefault();
                          setModal2IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText2}
                    </ButtonCTA>
                  )}
                </Div>
              </Col>
            </Row>
          </Container>
        </Div>
      )}
      {blade.bladeSizeType === 'small' && (
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Container>
            <Row className={`items-center`}>
              <Col xs={12} sm={7}>
                <H3 color={blade.backgroundColor != 'white' ? 'white' : 'black'}>{blade.title}</H3>
              </Col>
              <Col xs={12} sm={5}>
                <Div marginTop={[4, 0]} display="flex" flexWrap="wrap">
                  {blade.buttonText && blade.buttonText2 && (
                    <>
                      {blade.buttonText && (
                        <ButtonCTA
                          buttonColor={blade.buttonColor}
                          href={blade.buttonUrl}
                          className={`mr4-l mb2 mb1-l`}
                          trackingCategory="newhome-acquisition-blade"
                          trackingAction="click"
                          trackingLabel={`sign_up-[${blade.buttonUrl}]`}
                          onClick={e => {
                            if (blade.buttonUrl === '#') {
                              e.preventDefault();
                              setModal1IsOpen(true);
                            }
                          }}
                        >
                          {blade.buttonText}
                        </ButtonCTA>
                      )}
                      {blade.buttonText2 && (
                        <ButtonCTA
                          buttonColor={blade.buttonColor2}
                          href={blade.buttonUrl2}
                          trackingCategory="newhome-acquisition-blade"
                          trackingAction="click"
                          trackingLabel={`sign_up-[${blade.buttonUrl2}]`}
                          onClick={e => {
                            if (blade.buttonUrl2 === '#') {
                              e.preventDefault();
                              setModal2IsOpen(true);
                            }
                          }}
                        >
                          {blade.buttonText2}
                        </ButtonCTA>
                      )}
                    </>
                  )}
                  {blade.buttonText && !blade.buttonText2 && (
                    <ButtonCTA
                      buttonStyle={'extra-wide'}
                      buttonColor={blade.buttonColor}
                      href={blade.buttonUrl}
                      className={`mb0-l`}
                      trackingCategory="newhome-acquisition-blade"
                      trackingAction="click"
                      trackingLabel={`sign_up-[${blade.buttonUrl}]`}
                      onClick={e => {
                        if (blade.buttonUrl2 === '#') {
                          e.preventDefault();
                          setModal2IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText}
                    </ButtonCTA>
                  )}
                </Div>
              </Col>
            </Row>
          </Container>
        </Div>
      )}
      {modal1IsOpen &&
        formModal1 &&
        formModal1.marketoForm &&
        formModal1.marketoForm.marketoFormId && (
          <MarketoFormModal
            onSuccessBehavior="show-message"
            open={modal1IsOpen}
            setOpen={setModal1IsOpen}
            modal={formModal1}
          />
        )}
      {modal2IsOpen &&
        formModal2 &&
        formModal2.marketoForm &&
        formModal2.marketoForm.marketoFormId && (
          <MarketoFormModal
            onSuccessBehavior="show-message"
            open={modal2IsOpen}
            setOpen={setModal2IsOpen}
            modal={formModal2}
          />
        )}
    </Div>
  );
};
