import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { MarketoForm } from '../../common/Marketo/marketo-form';
import { Div, Input, Textarea, Label } from '../../common/Elements';
import { SelectDropdown } from '../../common/SelectDropdown';
import { Checkbox } from '../../common/CheckBox/Checkbox';
import { StyleSystemProps } from '../../common/Elements/Elements';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { CFAttention } from '../../common/CFIcons/CFAttention';
import { CFXExit } from '../../common/CFIcons/CFXExit';

const marketoFormId = 1969;
const leadSource = 'Outbound - Biz/Pro/Free List';
const leadSourceDetail = "[EXP] Q3'20 WEB - GBL - PAYGO WWW Product Recommendations";

export interface EmailRecommendationsModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  serializedData: string;
}

export const EmailRecommendationsModal: FC<EmailRecommendationsModalProps> = ({
  serializedData,
  open,
  setOpen,
}) => {
  const translate = useTranslations();

  const additionalFormData = {
    productRecommendation: serializedData,
  };

  const errorProps = (
    hasError: boolean
  ):
    | Pick<
        StyleSystemProps,
        'borderColor' | 'outlineColor' | 'outlineStyle' | 'outlineWidth' | 'outlineInnerOffset'
      >
    | {} =>
    !!hasError
      ? {
          borderColor: 'transparent',
          outlineColor: 'red0',
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineInnerOffset: 2,
        }
      : {};

  return (
    <>
      <Modal
        closeTimeoutMS={200}
        isOpen={open}
        className="bg-br-lightBlue h-auto min-h-100 outline-0"
        overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg--grayOpaque z-max overflow-y-scroll"
        contentLabel="Under Attack Form"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => {
          setOpen(false);
        }}
        ariaHideApp={true}
      >
        <Div
          flex={'auto'}
          maxWidth={6}
          marginBottom={[6, 11]}
          paddingHorizontal={[4, 4, 4, 6]}
          paddingVertical={[0, 0, 2, 6]}
          center
        >
          <MarketoForm
            onSuccess={() => setOpen(false)}
            additionalSubmitFormData={additionalFormData}
            InputElement={({ value, onChange, id, required, onBlur, className }) => {
              const hasError = className?.includes('hasError');
              return (
                <Input
                  onBlur={onBlur as any}
                  padding={2}
                  border={'all'}
                  borderColor="gray1"
                  marginBottom={1}
                  width={'100%'}
                  color="black"
                  autoComplete="none"
                  required={required}
                  id={id}
                  value={value}
                  backgroundColor="transparent"
                  focused={{
                    outlineColor: 'blue1',
                    outlineWidth: 2,
                    outlineStyle: 'solid',
                    outlineInnerOffset: 2,
                    borderColor: 'transparent',
                  }}
                  onChange={onChange as any}
                  {...errorProps(!!hasError)}
                />
              );
            }}
            TextAreaElement={({ value, onChange, id, required, onBlur, className }) => {
              const hasError = className?.includes('hasError');
              return (
                <Textarea
                  onBlur={onBlur as any}
                  padding={3}
                  marginBottom={2}
                  borderColor={'gray1'}
                  width={'100%'}
                  backgroundColor="transparent"
                  value={value}
                  focused={{
                    outlineColor: 'blue1',
                    outlineWidth: 2,
                    outlineStyle: 'solid',
                    outlineInnerOffset: 2,
                    borderColor: 'transparent',
                  }}
                  onChange={onChange as any}
                  id={id}
                  required={required}
                  {...errorProps(!!hasError)}
                />
              );
            }}
            SelectElement={({ onChange, value, id, options, required, className }) => {
              const hasError = className?.includes('hasError');
              return (
                <SelectDropdown
                  padding={3}
                  marginBottom={2}
                  id={id}
                  required={required}
                  borderColor="gray1"
                  color="black"
                  value={value}
                  onChange={onChange as any}
                  {...errorProps(!!hasError)}
                >
                  {options.map(o => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </SelectDropdown>
              );
            }}
            LabelElement={({ htmlFor, text }) => (
              <Div marginBottom={2}>
                <Label fontSize={2} lineHeight="copy" fontWeight={6} htmlFor={htmlFor}>
                  {text}
                </Label>
              </Div>
            )}
            CheckboxElement={({ id, checked, onChange, labelText, labelHtmlFor }) => (
              <Checkbox
                id={id || ''}
                checked={checked || false}
                value="yes"
                labelClassName="body-2"
                labelText={translate(labelText as any)}
                labelHtmlFor={labelHtmlFor}
                onChange={onChange}
              />
            )}
            ErrorElement={({ text, children }) => (
              <Div marginBottom={2} lineHeight={1} display="flex" alignItems="center">
                <CFAttention size={16} color="red0" />
                <Label marginLeft={1} color="red0" lineHeight="copy" fontWeight={4}>
                  {text}
                  {children}
                </Label>
              </Div>
            )}
            title={translate('Email_Recommendation') as string}
            subtitle={
              translate(
                'Please_complete_this_form_to_send_our_personalized_Cloudflare_product_recommendation_to_your_inbox_'
              ) as string
            }
            marketoData={{
              marketoFormId: marketoFormId,
              marketoFormLeadSourceDetail: leadSourceDetail,
              marketoFormLeadSource: leadSource,
            }}
          />
          <button
            className="absolute f6 gray5 pointer p1 bn bg--transparent"
            onClick={() => {
              setOpen(false);
            }}
            style={{ top: '5px', right: '-4px' }}
          >
            <CFXExit size={32} color="black" />
          </button>
        </Div>
      </Modal>
    </>
  );
};
