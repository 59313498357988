import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../../common/interfaces/locale-props.interface';
import { EnablementBlade } from '../../common/refresh-blades/EnablementBlade';
import { Divider } from '../../common/Divider';
import { A, Button, Div, H2, H3, H5, Li, P, Section, Span, Ul } from '../../common/Elements';
import { TopNav } from '../../layout';
import Layout from '../../layout/layout';
import { Grid, GridCol, GridRow } from '../../welcome-center/common/Grid';
import { HeroHeader } from '../../welcome-center/common/HeroHeader';
import { useLocation } from '@reach/router';
import { EmailRecommendationsModal } from './email-recommendations-modal';
import { MainFooter } from '../../layout/footer/footer.interfaces';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';

export interface RecommendationProps extends GatsbyTemplateProps<AppContextProps> {
  data: {
    navData: TopNav;
    footerData: MainFooter;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
  };
}

type CenterContainerProps = {
  className?: string;
};

const CenterContainer: FC<CenterContainerProps> = ({ children, className }) => {
  return (
    <Div maxWidth={9} center={true} paddingHorizontal={3} className={classNames(className)}>
      {children}
    </Div>
  );
};

type OrangeHeadingProps = {
  className?: string;
};

const OrangeHeading: FC<OrangeHeadingProps> = ({ children, className }) => {
  return (
    <H3 fontSize={2} color="br-orange" fontWeight="bold" className={classNames('ttu', className)}>
      {children}
    </H3>
  );
};

type OrangeChecklistProps = {
  items: string[];
  className?: string;
};

const OrangeChecklist: FC<OrangeChecklistProps> = ({ items, className }) => {
  return (
    <>
      {items.map(item => (
        <div className={classNames('flex items-center mv1', className)}>
          <img src="/img/icons/orange/checkmark.svg" className="mr3" />
          <span className="brandGray5 f2 lh-copy">{item}</span>
        </div>
      ))}
    </>
  );
};

export interface Addon {
  name: string;
  description: string;
  infolink: string;
  price: string;
  cta: string;
  ctalink: string;
  iconURL: string;
  mostPopular?: boolean;
}

interface AddonCardProps {
  data: Addon;
  showCTA?: boolean;
  fixedHeight?: number;
}

const Addons = [
  {
    recommendedFromAnswers: ['4a'],
    mostPopular: true,
    name: 'Intelligent Routing',
    description:
      'Argo is a service that uses optimized routes across the Cloudflare network to deliver responses to your users more quickly, reliably, and securely. Argo includes: Smart Routing, Tunnel, and Tiered Caching.',
    infolink: 'https://www.cloudflare.com/products/argo-smart-routing/',
    price: 'Starting at $5 per month',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/traffic/argo',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/3Cl6VgQZqJLbwYnUn4lFvQ/458ec58a03c24888cb9f20d2543355f2/_DO_NOT_USE__new-homepage_icon_performance-intelligent-routing_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: [],
    name: 'Dedicated SSL Certificate',
    description:
      'Dedicated SSL Certificates are automatically generated and propagate throughout our global content delivery network, providing robust encryption, along with lightning fast performance and compatibility.',
    infolink: 'https://www.cloudflare.com/ssl/dedicated-certificates/',
    price: 'Starting at $5 per month',
    cta: 'Buy',
    ctalink: 'https://dash.cloudflare.com/crypto',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/3Y13tTigGFzx34DZk3iS9Y/a9f589a0ae520160b3f955a15036d738/_DO_NOT_USE__new-homepage_icon_security-shield-protection_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: [],
    name: 'Serverless Applications - Workers',
    description:
      "Cloudflare Workers lets developers deploy serverless code written in JavaScript, Rust, C, and C++ to Cloudflare's edge, closer to the end user.",
    infolink: 'https://www.cloudflare.com/products/cloudflare-workers/',
    price: 'Try for free',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/workers',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/1adNPgKifnhJByXbHjNvRD/6b392da3176ff73f84561fa3039e9915/_DO_NOT_USE__new-homepage_icon_optimization-gear_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: ['3e'],
    name: 'Secure Remote Users with Access',
    description:
      'Cloudflare Access protects internal resources by securing, authenticating and monitoring access per-user and by application.',
    infolink: 'https://www.cloudflare.com/teams-pricing/',
    price: 'Starting at $3 per month',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/access',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/1Tz4MFKkKlNBzAkA1z0Tdc/cab15869855f35cab88b3c82f7201d2e/_DO_NOT_USE__new-homepage_icon_network-scale_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: ['4c'],
    mostPopular: true,
    name: 'Load Balancing',
    description:
      'Load Balancing safeguards from service disruptions with local and global traffic load balancing, geographic routing, server health checks, and failover, ensuring the continuous availability of your critical resources.',
    infolink: 'https://www.cloudflare.com/load-balancing/',
    price: 'Starting at $5 per month',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/traffic/load-balancing',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/69zoG6NB1K1rwuUMqC7PdH/18a9fa03e61fda2cbe7f1a55c2af20e7/_DO_NOT_USE__new-homepage_icon_reliability-load-balancer_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: ['4e'],
    name: 'Cloudflare Video Stream',
    description:
      'Cloudflare Stream makes streaming high quality video at scale, easy and affordable.',
    infolink: 'https://www.cloudflare.com/products/cloudflare-stream/',
    price: 'Starting at $5 per month',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/stream',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/5uu1ZGpzWEiF4CEzFzB5wL/077766974f80a6279f7e36177a8f387e/_DO_NOT_USE__new-homepage_icon_connect-3_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: ['4b'],
    mostPopular: true,
    name: 'Rate Limiting',
    description:
      'Rate Limiting protects against denial-of-service attacks, brute-force password attempts, and other types of abusive behavior targeting the application layer.',
    infolink: 'https://www.cloudflare.com/rate-limiting/',
    price: '$.05 per 10k good requests',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/firewall/managed-rules',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/4rY6l8s0HsXgllVNIavx8L/68abd21139a09067cc969f08d636c965/_DO_NOT_USE__new-homepage_icon_reliability-timer_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: [],
    name: 'Image Resizing',
    description:
      'Resize images for a variety of device types and connections from a single-source master image. Images can be manipulated by dimensions, compression ratios, and format (WebP conversion where supported).',
    infolink: 'https://www.cloudflare.com/website-optimization/#plan-features',
    price: '$.05 per 10k good requests',
    cta: 'Activate',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/speed/optimization',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/1YNXeuE42YGTpq8SdlKJOZ/dd77d81b183b2e51a44224f735bef34d/_DO_NOT_USE__new-homepage_icon_optimization-scale_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: [],
    name: 'Additional Page Rules ',
    description:
      "Page Rules allow you to customize Cloudflare's functionality to match the unique needs of your domain or subdomain, helping to optimize speed, harden security, increase reliability, maximize bandwidth savings, and much more.",
    infolink: 'https://www.cloudflare.com/features-page-rules/',
    price: '$5 for 5 rules',
    cta: 'Buy',
    ctalink: 'https://dash.cloudflare.com/?to=/:account/:zone/page-rules',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/7c27ZZvtaf4AkaX74J3PtP/47cfd84900c2036273ddab319f77990f/_DO_NOT_USE__new-homepage_icon_platform-apps_blue_48px_svg.svg',
  },
  {
    recommendedFromAnswers: ['1b', '4f', '4g'],
    name: 'Protect Employees & Devices',
    description:
      'Cloudflare for Teams is a Zero Trust platform that secures employee connections to applications and the Internet.',
    infolink: 'https://www.cloudflare.com/features-page-rules/',
    price: 'Starting at $0/user',
    cta: 'Buy',
    ctalink: 'https://www.cloudflare.com/teams/',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/ezbIxGGyxboePsSIvPPAK/bccd92997f10ab253b4cf0a8f709be8d/Teams_icon_user-multi_blue_80px_btm-lft_svg.svg',
  },
  {
    recommendedFromAnswers: ['1c'],
    name: 'Cloudflare for Your Entire Network',
    description:
      'Magic Transit delivers network functions at Cloudflare scale— that’s DDoS protection, traffic acceleration, and much more from every Cloudflare data center— for on-premise, cloud-hosted, and hybrid networks.',
    infolink: 'https://www.cloudflare.com/magic-transit',
    price: 'Starting at $0/user',
    cta: 'Buy',
    ctalink: 'https://www.cloudflare.com/magic-transit',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/7CzpRaPu9MRLDfD4xFpmWE/61383ce49be50c63955f6327ee1ddf1a/icon_network-scale_blue_48px.svg',
  },
  {
    recommendedFromAnswers: ['1d'],
    name: 'Build & Deploy Code at the Edge',
    description:
      'Deploy serverless code instantly across the globe to give it exceptional performance, reliability, and scale.',
    infolink: 'https://workers.cloudflare.com/',
    price: 'Starting at $0/user',
    cta: 'Buy',
    ctalink: 'https://www.cloudflare.com/teams/',
    iconURL:
      'https://images.ctfassets.net/slt3lc6tev37/799jm8r1Bxz7W7CduL0HMZ/b265dfb04c04fb39d0765f9652fe218e/cloudflare-workers-80x80-0ab91d2.png',
  },
];

const QuoteCard: FC = () => {
  return (
    <div
      className="pa3 mr3 bg-br-lightBlue flex flex-column items-start justify-between"
      style={{ height: '385px' }}
    >
      <img src="https://images.ctfassets.net/slt3lc6tev37/1hAAT5s62aZEg1AexAya52/385db1a87cf01623f74c94b35fcdb838/logo_ao_gray_32px-wrapper.svg"></img>
      <div className="f4 mt4 mb5">
        “Once we enabled Cloudflare, we immediately saw a 1.7 second decrease in page load times
        across all of our sites.”
      </div>

      <P marginBottom={0}>Austin Davies</P>
      <P marginTop={0} fontWeight="bold">
        DevOps Engineer
      </P>
    </div>
  );
};

const AddonCard: FC<AddonCardProps> = ({ data, showCTA = false, fixedHeight }) => {
  return (
    <div
      className="ba pa3 mr3 flex flex-column items-start justify-between"
      style={{ height: fixedHeight ? `${fixedHeight}px` : 'auto' }}
    >
      <img src={data.iconURL}></img>
      <H3 fontSize={4} fontWeight="bold" marginBottom={0}>
        {data.name}
      </H3>

      <div className="f2">
        {data.description && (
          <P lineHeight="copy" marginBottom={1} marginTop={0}>
            {data.description}
          </P>
        )}
      </div>

      <a href={data.infolink} className="b mb1">
        Learn more ›
      </a>

      {showCTA ? (
        <>
          <Divider></Divider>
          <P fontSize={2} fontWeight="bold" marginBottom={0}>
            {data.price}
          </P>
          <A
            fontWeight="bold"
            backgroundColor="blue1"
            color="white"
            border="none"
            paddingVertical={2}
            className="pointer dim"
            marginTop={3}
            marginBottom={0}
            width={['100%', '100%', '100%', '60%']}
            href={data.ctalink}
            display="inline-block"
            textAlign="center"
          >
            {data.cta}
          </A>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

const planFeatures = [
  {
    id: 'free',
    title: 'Free + Add-Ons',
    description: 'Personal or hobby projects that aren’t business critical.',
    price: 'Starting at $0/mo',
    coreFeatures: [
      'DDoS Attack Mitigation',
      'Global Content Delivery Network',
      'Support via email',
    ],
    supportTime:
      '<a href="https://community.cloudflare.com/">Community forums</a> available for tips and troubleshooting.',
    getStartedLink: 'https://dash.cloudflare.com/sign-up?pt=f',
  },
  {
    id: 'pro',
    title: 'Pro + Add-Ons',
    description: 'Protect and accelerate your professional websites.',
    price: 'Starting at $20/mo',
    coreFeatures: [
      'DDoS attack mitigation',
      'Global Content Delivery Network',
      'Support via email',
      'Enhanced WAF',
      'Image Optimization',
      'Mobile Optimization',
    ],
    supportTime:
      'Median email response time of less than 4 hours for urgent issues, and <a href="https://community.cloudflare.com/">Community forums</a> available for tips and troubleshooting.',
    getStartedLink: 'https://dash.cloudflare.com/sign-up?pt=p',
  },
  {
    id: 'business',
    title: 'Business + Add-Ons',
    description: 'PCI-compliant for small businesses.',
    price: 'Starting at $200/mo',
    coreFeatures: [
      'DDoS attack mitigation',
      'Global Content Delivery Network',
      'Support via email',
      'Enhanced WAF',
      'Image Optimization',
      'Mobile Optimization',
      '24x7x365 chat support',
      '100% uptime SLA',
      'CNAME set-up compatibility',
      'Easy PCI compliance',
    ],
    supportTime: 'Median email response time of less than 2 hours.',
    getStartedLink: 'https://dash.cloudflare.com/sign-up?pt=b',
  },
  {
    id: 'enterprise',
    title: 'Enterprise + Add-Ons',
    description: 'Advanced features and dedicated support.',
    price: 'Contact sales',
    coreFeatures: [
      'DDoS attack mitigation',
      'Global Content Delivery Network',
      'Support via email',
      'Enhanced WAF',
      'Image Optimization',
      'Mobile Optimization',
      '24x7x365 chat support',
      '100% uptime SLA',
      'CNAME set-up compatibility',
      '24x7x365 phone support',
      'Named solutions engineer support',
      '25x reimbursement',
      'Easy PCI compliance',
    ],
    supportTime: 'Median email response time of less than 1 hour.',
    getStartedLink: 'https://www.cloudflare.com/plans/enterprise/demo/',
  },
];

function getPlanFromRecommendation(recommendationValue: string) {
  /*
    Enterprise	Regex Matches	2d|2e|3d	Contact sales
    Business	Regex Matches	2c|3c|4d	$200/mo
    Pro	Regex Matches	2b|3b|4a|4b	$20/mo
    Free	Regex Matches	2a|3a	$0/mo
  */
  const regexMatch = [
    { id: 'enterprise', values: ['2d', '2e', '3d'] },
    { id: 'business', values: ['2c', '3c', '4d'] },
    { id: 'pro', values: ['2b', '3b', '4a', '4b'] },
    {
      id: 'free',
      values: ['2a', '3a'],
    },
  ];

  for (let item of regexMatch) {
    if (new RegExp(item.values.join('|')).test(recommendationValue)) {
      return item.id;
    }
  }
  return 'free';
}

export const query = graphql`
  query($locale: String!) {
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
  }
`;

const RecommendationPage: FC<RecommendationProps> = ({ data, pageContext }) => {
  const location = useLocation();
  const [recommendation, setRecommendation] = useState('');

  useEffect(() => {
    setRecommendation(new URLSearchParams(location.search).get('pr') || '');
  }, []);

  const defaultPlan = planFeatures[0];

  const [modalOpen, setOpenMode] = useState(false);
  const currentPlan =
    planFeatures.find(plan => plan.id === getPlanFromRecommendation(recommendation)) || defaultPlan;

  const defaultAddons = Addons.filter(addon => addon.mostPopular);

  const recommendedAddons = Addons.filter(addon =>
    addon.recommendedFromAnswers.some(answer => recommendation.includes(answer))
  );

  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      utilityNavData={data.utilityNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Personalized recommendation - Cloudflare</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <HeroHeader
        title="Here’s your personalized recommendation"
        subtitle="Based on the type of website you have and your needs, we recommend the following plan and
        add-ons."
        orientation="left"
        belowSubtitleLink="/about-your-website/"
        belowSubtitleLinkText="Start over ›"
      ></HeroHeader>

      <CenterContainer>
        <Grid className="pt7-l pv4">
          <GridRow>
            <GridCol width={2.5} className="pr6-l">
              <OrangeHeading>RECOMMENDED PLAN</OrangeHeading>
              <Divider></Divider>
              <H2
                fontSize={6}
                color="br-orange"
                fontWeight="bold"
                marginBottom={0}
                lineHeight="title"
              >
                {currentPlan.title}
              </H2>
              <P fontSize={2}>{currentPlan.description}</P>
              <Divider></Divider>
              <H3 fontSize={5} fontWeight={'bold'} marginBottom={0}>
                {currentPlan.price}
              </H3>
              <P fontSize={2} color={'gray1'}>
                billed annually
              </P>
              <A
                fontWeight="bold"
                backgroundColor="orange0"
                color="white"
                border="none"
                paddingVertical={2}
                className="pointer dim"
                marginBottom={3}
                href={currentPlan.getStartedLink}
                textAlign="center"
              >
                {currentPlan.id === 'enterprise' ? 'Request demo' : 'Get started'}
              </A>
              <EmailRecommendationsModal
                serializedData={recommendation || ''}
                open={modalOpen}
                setOpen={setOpenMode}
              />
              <Button
                fontWeight="bold"
                color="builtforthisblue"
                backgroundColor="white"
                paddingVertical={2}
                border="all"
                borderColor="builtforthisblue"
                className="b--builtForThisBlue pointer dim"
                marginBottom={3}
                onClick={e => setOpenMode(true) as any}
              >
                Email details to myself
              </Button>
              <A fontWeight="bold" color="br-blue" href="/plans/" marginVertical={1}>
                Compare all plans ›
              </A>
              <Divider></Divider>
              <OrangeHeading>CORE FEATURES</OrangeHeading>
              <OrangeChecklist items={currentPlan.coreFeatures}></OrangeChecklist>
              <P>
                <Span fontWeight="bold">Support response: </Span>
                <Span
                  dangerouslySetInnerHTML={{
                    __html: currentPlan.supportTime,
                  }}
                ></Span>
              </P>
            </GridCol>

            <GridCol width={1} className="pl4-l justify-start-ns">
              {recommendedAddons.length > 0 ? (
                <>
                  <OrangeHeading>ADD-ONS AND PRODUCT RECOMMENDATIONS</OrangeHeading>

                  <Grid>
                    <GridRow>
                      {recommendedAddons.map((addon, i) => (
                        <GridCol width={2} key={i + 1}>
                          <AddonCard data={addon} fixedHeight={385}></AddonCard>
                        </GridCol>
                      ))}
                    </GridRow>
                  </Grid>
                </>
              ) : (
                <>
                  <OrangeHeading>MOST POPULAR ADD-ONS</OrangeHeading>

                  <Grid>
                    <GridRow>
                      {defaultAddons.map((addon, i) => (
                        <GridCol width={2} key={i + 1}>
                          <AddonCard data={addon} fixedHeight={385}></AddonCard>
                        </GridCol>
                      ))}
                      <GridCol width={2}>
                        {/* FIXME: Why does this show up on enterprise page */}
                        <QuoteCard></QuoteCard>
                      </GridCol>
                    </GridRow>
                  </Grid>
                </>
              )}
            </GridCol>
          </GridRow>
        </Grid>
      </CenterContainer>

      <EnablementBlade
        blade={{
          contentfulId: 'contenfulId1234',
          backgroundColor: 'blue',
          contentTypeId: 'bladeEnablement',
          title: 'Protect and accelerate your websites, apps, and teams.',
          buttonColor: 'orange',
          buttonColor2: 'orange-outline',
          buttonText: 'Sign Up',
          buttonUrl: '/sign-up/',
          buttonText2: 'Contact Sales',
          buttonUrl2: '/plans/enterprise/contact/',
          bladeSizeType: 'large',
          textColor: 'white',
        }}
      ></EnablementBlade>
    </Layout>
  );
};

export default RecommendationPage;
